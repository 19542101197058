<template>
  <div class="pox">
    <div class="pox_box">
      <p class="p_title">水源井井管</p>
      <div class="motherMenu">
        <div
          class="motherMenu-box"
          v-for="(item, index) in titname"
          :key="index"
        >
          {{ item.name }}
          <i
            v-if="brType == item.id"
            class="el-icon-arrow-down"
            @click="handelbrType(0)"
          ></i>
          <i v-else class="el-icon-arrow-up" @click="handelbrType(item.id)"></i>
        </div>
      </div>
      <div class="meun_box" v-for="(item1, index1) in meunone" :key="index1">
        <div :class="[brType == item1.brnum ? 'menu' : 'menu1']">
          <div v-for="(item2, index2) in item1.proList" :key="index2">
            <p
              :class="[btnType == item2.num ? 'p2' : 'p1']"
              @click="choseType(item2.num)"
            >
              {{ item2.name }}
            </p>
          </div>
        </div>
      </div>
      <!-- <p class="p_title1" v-if="btnType == 0 || btnType == 1">
        球墨铸铁井管<span></span>
      </p>
      <div class="left_dh">
        <div
          :class="[btnType == 0 ? 'div_dh' : 'div_dh1']"
          @click="choseType(0)"
        >
          325
        </div>
      </div> -->
      <div class="cen_box">
        <div class="box_left">
          <div v-if="btnType == 1" class="left_img">
            <img src="../../../assets/imgs/nbht/xsg.png" alt="" />
          </div>
          <!-- <div v-if="btnType == 1" class="left_img">
            <img src="../../../assets/imgs/yc_st.png" alt="" />
          </div> -->
        </div>
        <div class="box_rig">
          <div class="rig_title">
            <div class="rig_ic"></div>
            <div class="rig_size">功能特点</div>
          </div>
          <div class="rig_cen" style="margin-bottom: 1.25vw">
            <ul v-for="(item, index) in list" :key="index">
              <li>
                {{ item.data }}
              </li>
            </ul>
          </div>
          <div class="rig_title">
            <div class="rig_ic1"></div>
            <div class="rig_size">应用场景</div>
          </div>
          <div class="rig_cen">
            <ul v-for="(item, index) in listdata" :key="index">
              <li>
                {{ item.data }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titname: [
        {
          id: 1,
          name: "球墨铸铁井管",
        },
      ],
      meunone: [
        {
          brnum: 1,
          proList: [
            { name: "YCJ-325/YCLJ-325", num: 1 },
          ],
        },
      ],
      listtit: [
        { name: "YCMD51(主)", code: 0 },
        { name: "YCMD41(从)", code: 1 },
        { name: "YCMD11", code: 2 },
      ],
      list: [
        { data: "80 目不锈钢滤网、扶正器" },
        {
          data: "支筋，浸塑铁丝缠绕，间隙 0.2mm",
        },
        {
          data: "丝接球墨铸铁管寿命是钢管的 6-7 倍",
        },
        {
          data: "Φ3-5mm 精选滤料",
        },
      ],
      listdata: [
        {
          data: "地下水过滤",
        },
      ],
      activeIndex: "1",
      btnType: 1,
      brType: 0,
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {
    handelbrType(brtype) {
      console.log(brtype);
      this.brType = brtype;
    },
    choseType(type) {
      this.btnType = type;
      if (type == 1) {
        this.list = [
          { data: "80 目不锈钢滤网、扶正器" },
          {
            data: "支筋，浸塑铁丝缠绕，间隙 0.2mm",
          },
          {
            data: "丝接球墨铸铁管寿命是钢管的 6-7 倍",
          },
          {
            data: "Φ3-5mm 精选滤料",
          },
        ];
        this.listdata = [
          {
            data: "地下水过滤",
          },
        ];
      }
    },
    handleSelect(key, keyPath) {
      //console.log(key, keyPath);
      this.$router.push(key);
    },
  },
};
</script>

<style scoped lang="less">
.meun_box:nth-child(4) {
  margin-left: 340px;
}

.menu {
  position: absolute;
  .p1 {
    height: 51px;
    width: 231px;
    background-color: rgba(26, 38, 64, 0.6);
    line-height: 51px;
    color: rgba(177, 177, 177, 1);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
  }
  .p2 {
    height: 51px;
    width: 321px;
    background-color: rgba(26, 38, 64, 0.6);
    line-height: 51px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
  }
}

.menu1 {
  display: none;
}
i {
  text-indent: 8px;
  font-size: 16px;
  font-weight: 900;
  cursor: pointer;
}
.motherMenu {
  width: 800px;
  height: 28px;
  // border: 1px solid gold;
  margin-top: 18px;
  display: flex;
  user-select: none;
  .motherMenu-box {
    width: 300px;
    height: 100%;
    // border: 1px solid green;
    margin-left: 40px;
    // text-align: center;
    line-height: 28px;
    color: rgba(61, 72, 93, 1);
    font-weight: 600;
    font-size: 18px;
  }
  .motherMenu-box:nth-child(1) {
    margin-left: 0px;
  }
}
.pox {
  width: 100%;
  height: 100%;
  .pox_box {
    width: 100%;
    height: 100%;
    padding: 32px;
    .p_title {
      width: 70%;
      height: 42px;
      font-size: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #1b1e20;
      line-height: 42px;
      // margin-left: 24px;
      // margin-top: 24px;
    }
    .p_title1 {
      width: 70%;
      height: 48px;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #3d485d;
      line-height: 48px;
      // margin-left: 24px;
      margin-top: 8px;
      span {
        color: #d8d8d8;
      }
    }
    .left_dh {
      width: 100%;
      height: 38px;
      font-size: 18px;
      margin-top: 8px;
      //border: 1px solid gray;
      display: flex;
      .div_dh {
        width: 66px;
        height: 38px;
        line-height: 38px;
        color: #0072ff;
        text-align: center;
        font-weight: 500;
        border-bottom: 3px solid #0072ff;
        cursor: pointer;
      }
      .div_dh1 {
        width: 66px;
        height: 38px;
        line-height: 38px;
        color: #b1b1b1;
        text-align: center;
        cursor: pointer;
        // font-weight: 600;
        //border-bottom: 3px solid #0072ff;
      }
    }
    .cen_box {
      width: 100%;
      height: 320px;
      //border: 1px solid gold;
      margin-top: 87px;
      display: flex;
      .box_left {
        width: 400px;
        // margin-left: 24px;

        //border: 1px solid gray;

        .left_img {
          width: 400px;
          height: 300px;
          // margin:0 auto;
          // margin-left: 150px;
          // margin-top: 35px;
          //border: 1px solid goldenrod;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .box_rig {
        width: 407px;
        // border: 1px solid gray;
        margin-left: 160px;
        .rig_title {
          width: 100%;
          height: 24px;
          // border: 1px solid gold;
          margin-left: -26px;
          display: flex;
          .rig_ic {
            width: 24px;
            height: 24px;
            // margin-top: 5px;
            background-image: url("../../../assets/imgs/ycic11.png");
            background-size: 100% 100%;
            // border: 1px solid gray;
          }
          .rig_ic1 {
            width: 24px;
            height: 24px;
            // margin-top: 5px;
            background-image: url("../../../assets/imgs/ycic22.png");
            background-size: 100% 100%;
            // border: 1px solid gray;
          }
          .rig_size {
            width: 70%;
            height: 24px;
            margin-left: 8px;
            line-height: 24px;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #1b1e20;
            line-height: 24px;
          }
        }
        .rig_cen {
          width: 100%;
          margin-top: 8px;

          ul li {
            padding-left: 6px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #3d485d;
            line-height: 28px;
          }
        }
        .rig_tit2 {
          width: 100%;
          height: 24px;
          // border: 1px solid goldenrod;
          margin-top: 28px;
        }
        .rig_cen {
          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
