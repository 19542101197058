<template>
  <div class="pox">
    <div class="pox_box">
      <p class="p_title">未端智慧管理器系列</p>
      <div class="p_title1">
        未端智慧管理器<span>(一拖四智慧管理器)</span
        ><i
          v-if="brType == 1"
          class="el-icon-arrow-down"
          @click="handelbrType(0)"
        ></i>
        <i v-else class="el-icon-arrow-up" @click="handelbrType(1)"></i>
      </div>
      <div :class="[brType == 1 ? 'menu' : 'menu1']">
        <p :class="[btnType == 1 ? 'p2' : 'p1']" @click="choseType(1)">YCM051(主)</p>
        <p :class="[btnType == 2 ? 'p2' : 'p1']" @click="choseType(2)">YCM041(从)</p>
        <p :class="[btnType == 3 ? 'p2' : 'p1']" @click="choseType(3)">YCM011</p>
      </div>
      <!-- <p class="p_title1" v-if="btnType == 1">
        未端智慧管理器<span>(一拖四智慧管理器)</span>
      </p>
      <p class="p_title1" v-if="btnType == 2">
        未端智慧管理器<span>(一拖四智慧管理器)</span>
      </p> -->
      <!-- <div class="left_dh">
        <div
          :class="[btnType == 0 ? 'div_dh' : 'div_dh1']"
          @click="choseType(0)"
        >
          YCMD51(主)
        </div>
        <div
          :class="[btnType == 1 ? 'div_dh' : 'div_dh1']"
          @click="choseType(1)"
          style="margin-left: 1.61458vw"
        >
          YCMD41(从)
        </div>
        <div
          :class="[btnType == 2 ? 'div_dh' : 'div_dh1']"
          @click="choseType(2)"
          style="margin-left: 1.61458vw"
        >
          YCMD11
        </div>
      </div> -->
      <div class="cen_box">
        <div class="box_left">
          <div class="left_img">
            <img src="../../../assets/imgs/nbht/sb1.png" alt="" />
          </div>
        </div>
        <div class="box_rig">
          <div class="rig_title">
            <div class="rig_ic"></div>
            <div class="rig_size">功能特点</div>
          </div>
          <div class="rig_cen" style="margin-bottom: 1.25vw">
            <ul v-for="(item, index) in list" :key="index">
              <li>
                {{ item.data }}
              </li>
            </ul>
          </div>
          <div class="rig_title">
            <div class="rig_ic1"></div>
            <div class="rig_size">应用场景</div>
          </div>
          <div class="rig_cen">
            <ul v-for="(item, index) in listdata" :key="index">
              <li>
                {{ item.data }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listtit: [
        -{ name: "YCMD51(主)", code: 0 },
        { name: "YCMD41(从)", code: 1 },
        { name: "YCMD11", code: 2 },
      ],
      list: [
        { data: "两路温度传感器分别监测供、回水温度" },
        { data: "一路模拟量输出控制模拟量设备" },
        { data: "六路数字量输出分别控制地暖、风盘电动阀、风盘高中低风速" },
        { data: "一路 RS485 连接YCD501，配置用户参数" },
        { data: "一路模拟量输入采集模拟量数据" },
        {
          data: "WIFI、4G、LORA 等型式组网，避免繁琐的布线，具有传输距离远，超低功耗，穿透性强，灵敏度高，抗干扰性强",
        },
      ],
      listdata: [{ data: "应用于管理风盘、地暖、风柜等末端设备" }],
      activeIndex: "1",
      btnType: 1,
      brType: 0,
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {
    handelbrType(brtype) {
      console.log(brtype);
      this.brType = brtype;
    },
    choseType(type) {
      this.btnType = type;
    },
    handleSelect(key, keyPath) {
      //console.log(key, keyPath);
      this.$router.push(key);
    },
  },
};
</script>

<style scoped lang="less">
i {
  text-indent: 8px;
  font-size: 16px;
  font-weight: 900;
  cursor: pointer;
}
.pox {
  width: 100%;
  height: 100%;

  .pox_box {
    width: 100%;
    height: 100%;
    padding: 32px;
    // border: 1px solid gold;
    .menu {
      position: absolute;
      .p1 {
        height: 51px;
        width: 286px;
        background-color: rgba(26, 38, 64, 0.6);
        line-height: 51px;
        color: rgba(177, 177, 177, 1);
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
      }
      .p2 {
        height: 51px;
        width: 286px;
        background-color: rgba(26, 38, 64, 0.6);
        line-height: 51px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
      }
    }
    .menu1 {
      display: none;
    }
    .p_title {
      width: 70%;
      height: 42px;
      font-size: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #1b1e20;
      line-height: 42px;
      // margin-left: 24px;
      // margin-top: 24px;
    }
    .p_title1 {
      width: 70%;
      height: 28px;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #3d485d;
      line-height: 28px;
      // margin-left: 24px;
      margin-top: 18px;
      user-select: none;
      // border: 1px solid gold;
      // cursor: pointer;
      span {
        color: #d8d8d8;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .left_dh {
      width: 100%;
      height: 38px;
      // border: 1px solid gray;
      margin-top: 8px;
      display: flex;
      .div_dh {
        width: 103px;
        height: 38px;
        line-height: 38px;
        color: #0072ff;
        text-align: center;
        font-weight: 500;
        cursor: pointer;
        border-bottom: 3px solid #0072ff;
        font-size: 18px;
      }
      .div_dh1 {
        width: 103px;
        height: 38px;
        line-height: 38px;
        color: #b1b1b1;
        text-align: center;
        cursor: pointer;
        font-size: 18px;
        // font-weight: 600;
        //border-bottom: 3px solid #0072ff;
      }
    }
    .cen_box {
      width: 100%;
      // height: 340px;
      // border: 1px solid gold;
      // margin-top: 40px;
      display: flex;
      .box_left {
        width: 400px;
        // margin-left: 24px;
        // border: 1px solid gray;
        margin-top: 85px;
        .left_img {
          width: 400px;
          height: 300px;
          // margin-left: 100px;
          // margin-top: 10px;
          // border: 1px solid goldenrod;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .box_rig {
        width: 415px;
        // border: 1px solid gray;
        margin-left: 160px;
        margin-top: 75px;
        .rig_title {
          width: 100%;
          height: 24px;
          // border: 1px solid gold;
          margin-left: -26px;
          display: flex;
          .rig_ic {
            width: 24px;
            height: 24px;
            // margin-top: 5px;
            background-image: url("../../../assets/imgs/ycic11.png");
            background-size: 100% 100%;
            // border: 1px solid gray;
          }
          .rig_ic1 {
            width: 24px;
            height: 24px;
            // margin-top: 5px;
            background-image: url("../../../assets/imgs/ycic22.png");
            background-size: 100% 100%;
            // border: 1px solid gray;
          }
          .rig_size {
            width: 70%;
            height: 24px;
            margin-left: 8px;
            line-height: 24px;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #1b1e20;
            line-height: 24px;
          }
        }
        .rig_cen {
          width: 100%;
          margin-top: 8px;

          ul li {
            padding-left: 6px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #3d485d;
            line-height: 28px;
          }
        }
        .rig_tit2 {
          width: 100%;
          height: 24px;
          // border: 1px solid goldenrod;
          margin-top: 28px;
        }
        .rig_cen {
          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
