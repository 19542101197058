<template>
  <div class="app">
    <div class="box">
      <div class="data"></div>
      <div class="data1">
        <div class="data_cen">
          <Manager v-if="key == -1"></Manager> 
          <Panel v-if="key == 0"></Panel>
          <Modulel v-if="key == 1"></Modulel>
          <Sensor v-if="key == 2"></Sensor>
          <Gateway v-if="key == 3"></Gateway>
          <Flowmeter v-if="key == 4"></Flowmeter>
          <Gateway v-if="key == 5"></Gateway>
          <Hand v-if="key == 6"></Hand>
          <Valvel v-if="key == 7"></Valvel>
          <Stone v-if="key == 8"></Stone>
          <Wellcasing v-if="key == 9"></Wellcasing>
        </div>
      </div>
      <div class="btm"><Contact></Contact></div>
    </div>
  </div>
</template>

<script>
import Contact from "../home/components/Contact.vue";
import Manager from "../more/components/Manager";
import Panel from "../more/components/Panel.vue";
import Modulel from "../more/components/Modulel.vue";
import Sensor from "../more/components/Sensor.vue";
import Gateway from "../more/components/Gateway.vue";
import Flowmeter from "../more/components/Flowmeter.vue";
import Hand from "../more/components/Hand.vue";
import Valvel from "../more/components/Valvel.vue";
import Stone from "../more/components/Stone.vue";
import Wellcasing from "../more/components/Wellcasing.vue";
export default {
  // 组件名称
  name: "more",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {
    Contact,
    Manager,
    Panel,
    Modulel,
    Sensor,
    Gateway,
    Flowmeter,
    Hand,
    Valvel,
    Stone,
    Wellcasing,
  },
  // 组件状态值
  data() {
    return {
      activeIndex: "/more",
      key: "",
      children:"/more",
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {
    this.key = localStorage.getItem("key");
    var currentURL = window.location.href;
    // console.log(currentURL , '454')
    this.$emit("passfunction", currentURL);
  },
  // 组件实例挂载完毕
  mounted() {},
  destroyed() {},
  computed: {},
  destroyed() {},
  // 组件方法
  methods: {},
};
</script>

<style scoped lang="less">
.app {
  width: 100%;
  height: 100%;
  background-color: aquamarine;
  display: flex;
  flex-direction: column;
  padding: 0.1px;
  .box {
    position: absolute;
    color: aqua;
    background-image: url("../../assets/imgs/yc_bj.png");
    background-size: 100% 100%;
    //margin-top: 80px;
    //padding-top: 80px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    //min-height: 616px;
    // background-color: gray;
    .data {
      width: 100%;
      min-height: 80px;
    }

    .data1 {
      width: 99.6%;
      flex: 1;

      //height: 68%;
      // min-height: 616px;
      display: flex;
      margin: auto auto;
      // border: 1px solid gold;
      .data_cen {
        width: 1040px;
        height: 553px;
        margin: auto auto;
        background-color: #ffffff;
        box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 0.5);
        // margin: 0 auto;
        //  margin-top: 37px;
        //display: flex;
      }
    }
    .btm {
      width: 100%;
      min-height: 214px;

      // position: absolute;
      bottom: 0px;
      background-color: #101820;
    }
  }
}
</style>
